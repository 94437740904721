





















































































































































































































































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import DetailPage from '@/components/search-results/DetailPage.vue'
import Competence from '@/components/hszg/Competence.vue'
import { Action, Getter } from 'vuex-class'
import { CompleteJobOffer } from '@/store/jobAd/types'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import { GET_JOB_AD_BY_ID } from '@/store/jobAd/actions'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import {
  ACCEPT,
  DECLINE, getAcceptDeclinePositiveFeedback,
  getCompanyInquiryConfirmationExplanationForStudent,
  getCompanyInquiryConfirmationHeadingForStudent
} from '@/common/inquiry'
import { ACCEPT_DECLINE } from '@/store/inquiry/actions'
import { GenericRequest, SimpleServerResponse, User } from '@/store/types'
import ProfilePicture from '@/components/common/ProfilePicture.vue'
import InquiryModal from '@/components/forms/InquiryModal.vue'
import { CompanyProfile } from '@/store/company/types'
import { ApplicationModel, InquiryDto } from '@/store/inquiry/types'
import { INQUIRE_COMPANY, INQUIRE_COMPANY_CANCEL } from '@/store/student/actions'
import Enums from '@/common/Enums'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'

@Component({
  components: { ProfilePicture, LoadingSpinner, Competence, DetailPage, ConfirmationModal, InquiryModal }
})
export default class JobAd extends Vue {
  @Prop({ required: false })
  inquiryId: string

  @Prop({ required: true })
  isApplicationProp: string

  @Action(GET_JOB_AD_BY_ID)
  public getJobAdById: (params: { id: string; preview: boolean }) => Promise<CompleteJobOffer>

  @Action(INQUIRE_COMPANY)
  inquireCompany: (requestDto: InquiryDto) => Promise<InquiryDto>

  @Action(ACCEPT_DECLINE)
  public acceptOrDecline: (dto: GenericRequest) => Promise<SimpleServerResponse>

  @Action(INQUIRE_COMPANY_CANCEL)
  inquireCompanyCancel: (requestDto: GenericRequest) => Promise<SimpleServerResponse>

  @Getter('userGetter') userGetter: User

  isLoading = false
  showErrorMessage = false
  errorMessage = ''
  modalHeading = ''
  modalExplanation = ''
  inquiryButtons: string[] = []
  isApplication = true
  desiredAction = ''
  respondent: CompanyProfile | undefined
  showInquiryWarning = false

  jobAd: CompleteJobOffer

  get relevance (): string[] {
    return Object.keys(this.$i18n.t('job-ad.relevance'))
  }

  get DECLINE (): string {
    return DECLINE
  }

  get ACCEPT (): string {
    return ACCEPT
  }

  isAnOption (option: string): boolean {
    return this.inquiryButtons.includes(option)
  }

  get inquiryGetter () {
    return Enums
  }

  created (): void {
    this.loadData()
    this.isApplication = this.isApplicationProp === 'true'
    if (this.$route.params.options) this.inquiryButtons = JSON.parse(this.$route.params.options)
    if (this.$route.params.inquiryId) this.inquiryId = this.$route.params.inquiryId
  }

  loadData (): void {
    this.isLoading = true
    if (this.$route.params.id) {
      this.getJobAdById({ id: this.$route.params.id, preview: this.$route.params.preview === 'preview' }).then(data => {
        this.jobAd = data
      }).catch(() => {
        this.$root.$emit('alert',
          this.$t('warning.error!').toString(),
          this.$tc('warning.could-not-load', 2, { what: this.$tc('job-ad.job-ad', 1) }).toString(),
          true)
      }).finally(() => {
        this.isLoading = false
      })
    } else {
      this.$root.$emit('alert',
        this.$t('warning.error!').toString(),
        this.$t('warning.no-id').toString(),
        true)
    }
  }

  get hasRightToApply (): boolean {
    return hasRight(Rights.INQUIRE_COMPANY)
  }

  get hasRightToReactToInquiry (): boolean {
    return hasRight(Rights.INQUIRY_ANSWER)
  }

  inquiryResponse (): void {
    let acceptInquiry = 'false'
    if (this.desiredAction === ACCEPT) {
      acceptInquiry = 'true'
    }
    let inquiryId = ''
    if (this.jobAd.inquiryDTO?.inquiryId !== undefined && this.jobAd.inquiryDTO?.inquiryId !== null) {
      inquiryId = this.jobAd.inquiryDTO.inquiryId
    }
    this.acceptOrDecline({ query: [acceptInquiry, inquiryId] }).then(() => {
      const message = getAcceptDeclinePositiveFeedback(this.desiredAction)
      this.inquiryButtons = this.desiredAction === ACCEPT ? [DECLINE] : [ACCEPT]
      this.$root.$emit('alert', message, '', false)
    }).catch(() => {
      this.$root.$emit('alert', this.$i18n.t('warning.general-error-message').toString(), '', true)
    })
    this.loadData()
  }

  applyToJob (): void {
    this.respondent = this.jobAd.companyDTO
    this.showInquiryWarning = false
    this.$bvModal.show('company-inquiry-modal')
  }

  getQualificationIcon (synonymId: string): string {
    if (this.jobAd.missingStrictQualifications && this.jobAd.missingSoftQualifications) {
      const strict = !this.jobAd.missingStrictQualifications.some(el => el.synonymId === synonymId)
      const soft = !this.jobAd.missingSoftQualifications.some(el => el.synonymId === synonymId)
      if (strict && soft) return 'done_all'
      else if (soft) return 'done'
      else return ''
    } else return ''
  }

  openConfirmationModal (action: string): void {
    this.modalExplanation = getCompanyInquiryConfirmationExplanationForStudent(action)
    this.modalHeading = getCompanyInquiryConfirmationHeadingForStudent(action)
    this.desiredAction = action
    this.$bvModal.show('confirmation-modal-inquiry')
  }

  sendCompanyInquiry (model: ApplicationModel): void {
    if (model.text.trim().length > 0) {
      const inquiryModel: InquiryDto = {
        companyId: this.respondent?.profileId as string,
        inquiryText: model.text,
        cvLayout: model.unlockPdfDownload ? model.cvLayout : undefined,
        jobAdId: this.jobAd.jobAdDTO.jobAdId as string,
        talentId: this.userGetter.appUserId
      }
      this.inquireCompany(inquiryModel).then(data => {
        this.$bvModal.hide('company-inquiry-modal')
        if (this.jobAd.inquiryDTO !== undefined) {
          this.jobAd.inquiryDTO.inquiryStatus = data.inquiryStatus
          this.jobAd.inquiryDTO.inquiryId = data.inquiryId
          this.$root.$emit('alert', this.$i18n.t('job-ad.matching.inquiry-successful').toString(), '')
        }
        this.respondent = undefined
        this.loadData()
      }).catch(error => {
        this.errorMessage = error.message === 'inquiry already existent' ? this.$t('talent-inquiry.inquiry-already-existent').toString() : this.$t('warning.general-error-message').toString()
        this.showInquiryWarning = true
      })
    } else {
      this.showInquiryWarning = true
    }
  }

  openCancellationConfirmModal (): void {
    this.$bvModal.show('confirmation-modal-cancel-inquiry')
  }

  cancelInquiry (): void {
    if (this.jobAd.inquiryDTO !== undefined) {
      const requestDto: GenericRequest = {
        query: [this.jobAd.inquiryDTO.inquiryId as string],
        params: undefined
      }
      this.inquireCompanyCancel(requestDto).then(() => {
        if (this.jobAd.inquiryDTO !== undefined) {
          this.jobAd.inquiryDTO.inquiryStatus = null
          this.jobAd.inquiryDTO.inquiryId = null
          this.$root.$emit('alert', this.$i18n.t('job-ad.matching.inquiry-canceled').toString(), '')
        }
        this.respondent = undefined
        this.loadData()
      }).catch(error => {
        this.errorMessage = error
      })
    }
  }
}
